<template>
  <div class="homepage-slides-wrapper">
    <div
      class="
        homepage-slides
        text-center
        owl-carousel owl-theme owl-loaded owl-text-select-on
      "
    >
      <div class="single-slide-item slide-bg-1"></div>
      <!-- Slider item1 end-->

      <!-- Slider item2 start-->
      <div class="single-slide-item slide-bg-2"></div>
      <!-- Slider item2 end-->
    </div>
  </div>
  <!-- Slider End -->
</template>

<script>
export default {
  name: "Slider",
  components: {},
  mounted() {
    // eslint-disable-next-line no-undef
    $(".homepage-slides").owlCarousel({
      items: 1,
      nav: true,
      dots: false,
      autoplay: true,
      loop: true,
      navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>",
      ],
      mouseDrag: false,
      touchDrag: true,
      animateOut: "fadeOut",
    });
  },
};
</script>

<style></style>
