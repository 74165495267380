<template>
  <BeeHeader />
  <!-- Slider Start -->
  <!-- Slider Start -->
  <Slider />

  <!-- About BeeKeeping start -->
  <div class="bee-content-block bg-light-gray">
    <div class="container">
      <div class="row">
        <div class="col-12 wow fadeInUp">
          <div class="section-title">
            <h2 class="title-bg">About BeeKeeping for beginners</h2>
          </div>
          <div class="welcome-content">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s,when an unknown
              printer took a galley of type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged.when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries,
              but also the leap into electronic typesetting, remaining essentially unchanged.
            </p>
            <a href="about.html" class="bee-button">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About BeeKeeping End -->

  <FeatureProducts />

  <Training />

  <FeatureHoneyProducts />

  <!-- About Bee start -->
  <div class="bee-content-block pt0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title wow fadeInUp">
            <h2 class="title-bg">About Bees</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-lg-4">
          <div class="about-bee wow fadeInLeft">
            <div class="bee-icon">
              <img src="../assets/images/queen-bee.png" alt="Queen Bee" />
            </div>
            <h4>Queen Bee</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever.
            </p>
            <a href="#" title="" class="readmore">Read More <i class="fa fa-angle-right"></i></a>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <div class="about-bee wow fadeInUp">
            <div class="bee-icon">
              <img src="../assets/images/drone-bee.png" alt="Drone Bee" />
            </div>
            <h4>Drone Bee</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever.
            </p>
            <a href="#" title="" class="readmore">Read More <i class="fa fa-angle-right"></i></a>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <div class="about-bee wow fadeInRight">
            <div class="bee-icon">
              <img src="../assets/images/worker-bee.png" alt="Worker Bee" />
            </div>
            <h4>Worker Bee</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever.
            </p>
            <a href="#" title="" class="readmore">Read More <i class="fa fa-angle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About Bee End -->

  <!-- Contact Button start -->
  <div class="bee-content-block order-request-btn-sec">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9 wow fadeInLeft">
          <h4>
            The Best quality product, for <span>Beekeeping</span> with
            <span>Bee!</span>
          </h4>
        </div>
        <div class="col-12 col-lg-3 text-right wow fadeInRight">
          <a href="" title="" class="bee-button">Request For Order</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Button End -->

  <Testimonial />

  <Newsletter />
</template>

<script>
// @ is an alias to /src

import Newsletter from "@/components/Newsletter.vue";
import BeeHeader from "@/components/Header.vue";
import FeatureProducts from "@/components/FeatureProducts.vue";
import FeatureHoneyProducts from "@/components/FeatureHoneyProducts.vue";
import Slider from "@/components/Slider.vue";
import Testimonial from "@/components/Testimonial.vue";
import Training from "@/components/Training.vue";

export default {
  name: "Home",
  components: {
    Newsletter,
    BeeHeader,
    FeatureProducts,
    FeatureHoneyProducts,
    Slider,
    Testimonial,
    Training,
  },
};
</script>
