<template>
  <!-- Beekeeping Training start -->
  <div class="bee-content-block feature-product-dark-bg">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title wow fadeInUp">
            <h2 class="title-bg">Beekeeping Training For Beginner</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="training-box">
            <a href="" title="">
              <div class="training-icon">
                <img src="../assets/images/bee-training-1.png" alt="" />
              </div>
              <h4>Handling of beehives</h4>
              <p>
                It has survived not only five centuries, but also the leap into electronic
                typesetting.
              </p>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="training-box">
            <a href="" title="">
              <div class="training-icon">
                <img src="../assets/images/bee-training-2.png" alt="" />
              </div>
              <h4>Beehive Nutrition</h4>
              <p>
                It has survived not only five centuries, but also the leap into electronic
                typesetting.
              </p>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="training-box">
            <a href="" title="">
              <div class="training-icon">
                <img src="../assets/images/bee-training-3.png" alt="" />
              </div>
              <h4>Movement of bees</h4>
              <p>
                It has survived not only five centuries, but also the leap into electronic
                typesetting.
              </p>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="training-box">
            <a href="" title="">
              <div class="training-icon">
                <img src="../assets/images/bee-training-4.png" alt="" />
              </div>
              <h4>Honey Extraction</h4>
              <p>
                It has survived not only five centuries, but also the leap into electronic
                typesetting.
              </p>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container text-center">
          <a href="training.html" class="bee-button-white mt80">Check Our All Training</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Beekeeping Training End -->
</template>

<script>
export default {
  name: "Training",
};
</script>

<style></style>
